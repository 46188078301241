import {blurForm, focusForm} from '../behaviors/inline-comment'
import {clearSelection, getCurrentRange, isMultiLineCommentAllowed} from './linkable-line-number'
import {TemplateInstance} from '@github/template-parts'
import {changeValue} from '@github-ui/form-utils'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {parseHTML} from '@github-ui/parse-html'
import {remoteForm} from '@github/remote-form'
import {validateInlineCommmentsContainer} from '../pulls/suggested-changes'
import {sendUpdateThreadPreviewsEvent} from '@github-ui/pull-request-files-toolbar/rails-action-event'

let sequence = 0

// "+" button
//
// Insert new row and setup a fresh comment form.
on('click', '.js-add-file-comment', function (event) {
  const button = event.currentTarget as Element

  const file = button.closest<HTMLElement>('.file')!
  ensureInlineNotesEnabled(file)

  // Find or insert a tr.inline-comments row after the current line
  const threadRow = existingFileComment(button) || insertFileComment(button)

  // Find or insert a new comment thread form
  const thread = findOrInsertCommentThread(threadRow.querySelector('td') as Element, button)

  // fill out hidden form fields
  const form = thread.querySelector('.js-inline-comment-form')!
  if (form instanceof HTMLFormElement) {
    // hide suggestion button
    const suggestionButton = form.getElementsByClassName('js-suggestion-button-placeholder')[0] as HTMLElement
    if (suggestionButton) {
      suggestionButton.style.display = 'none'
    }

    fillTemplateFormFields(form, button)
  }
  focusForm(thread)
})

function existingFileComment(button: Element): Element | undefined {
  const thisHeader = button.closest<HTMLElement>('.file-header')!
  const table = next(thisHeader, '.diff-table')
  if (table instanceof Element) {
    const commentsContainer = table.querySelector('.js-inline-comments-container')
    if (commentsContainer instanceof Element) {
      return commentsContainer
    }
  }
}

// "+" button
//
// Insert new row and setup a fresh comment form.
on('click', '.js-add-single-line-comment', function (event) {
  const button = event.currentTarget as Element

  const file = button.closest<HTMLElement>('.file')!
  ensureInlineNotesEnabled(file)

  // Find or insert a tr.inline-comments row after the current line
  const threadRow = existingCommentRow(button) || insertCommentRow(button)

  // Find or insert a new comment thread form
  const thread = findOrInsertCommentThread(threadRow.querySelector('td') as Element, button)

  // fill out hidden form fields
  const form = thread.querySelector('.js-inline-comment-form')!
  if (form instanceof HTMLFormElement) {
    fillTemplateFormFields(form, button)
  }
  focusForm(thread)
})

// We have already inserted a comment row due to previous user interaction -
// so let's be sure the form gets added to the right spot!
function existingCommentRow(button: Element): Element | undefined {
  const thisRow = button.closest<HTMLElement>('tr')!
  const commentsContainer = next(thisRow, '.js-inline-comments-container')
  if (commentsContainer instanceof Element) {
    return commentsContainer
  }
}

function next(el: Element, selector: string): Element | null {
  const sibling = el.nextElementSibling
  return sibling && sibling.matches(selector) ? sibling : null
}

// Insert a new comment form row via the hidden template
export function insertCommentRow(button: Element): Element {
  const thisRow = button.closest<HTMLElement>('tr')!
  const nextRow = renderCommentTemplate('js-inline-comments-single-container-template', button).firstElementChild!
  thisRow.after(nextRow)
  return nextRow
}

// Insert a new comment form for a file header via the hidden template
function insertFileComment(button: Element): Element {
  const thisHeader = button.closest<HTMLElement>('.file-header')!
  const table = document.createElement('table')
  table.className = 'diff-table'
  const nextRow = renderCommentTemplate('js-inline-comments-single-container-template', button).firstElementChild!
  table.appendChild(nextRow)
  thisHeader.after(table)
  return nextRow
}

// For a given comment threads container, find or insert
// an unused comment form.
function findOrInsertCommentThread(comments: Element, button: Element): Element {
  const existingForm = Array.from(comments.querySelectorAll('.review-comment-form-container')).pop()
  if (existingForm) {
    return existingForm.closest('div.js-line-comments') as Element
  } else {
    const newThread = renderCommentTemplate('js-inline-comments-single-container-template', button).querySelector(
      'div.js-line-comments',
    )!
    comments.appendChild(newThread)
    return newThread
  }
}

on('click', '.js-add-split-line-comment', function (event) {
  const button = event.currentTarget

  const file = button.closest<HTMLElement>('.file')!
  ensureInlineNotesEnabled(file)

  const row = button.closest<HTMLElement>('tr')!
  const className = button.getAttribute('data-type') === 'addition' ? 'js-addition' : 'js-deletion'

  // Find or insert a tr.inline-comments row after the current line
  const threadRow = inlineCommentsSplitContainerForLine(row)

  // Find the left/right cell based on whether the comment is on an
  // addition or deletion
  const td = expandLineCommentsEmptyCells(threadRow, className, button)

  // Find or insert a new comment thread form in the appropriate column
  const thread = findOrInsertCommentThread(td, button)

  // fill out hidden form fields
  const form = thread.querySelector('.js-inline-comment-form')!
  if (form instanceof HTMLFormElement) {
    fillTemplateFormFields(form, button)
  }

  focusForm(thread)
})

// Handle "Comment on this line"
remoteForm('.js-inline-comment-form', async function (form, wants) {
  resetInlineCommentError(form)

  let response
  try {
    response = await wants.json()
  } catch (err) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (err.response) {
      let errData
      try {
        // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
        errData = err.response.json
      } catch {
        // ignore
      }
      if (errData) {
        handleInlineCommentError(form, errData)
        return
      }
    }
    throw err
  }

  sendUpdateThreadPreviewsEvent()

  const data = response.json

  // Add new comment at the end of the thread.
  const commentHtml = data['inline_comment']
  const container = form.closest('.js-line-comments')!
  if (commentHtml) {
    container.querySelector<HTMLElement>('.js-comments-holder')!.append(parseHTML(document, commentHtml))
  }

  // Replace previous form with new thread and updated form in response.
  const threadHtml = data['inline_comment_thread']
  if (threadHtml) {
    const fragment = parseHTML(document, threadHtml)
    let replaced = false

    if (data['replace_container']) {
      const commentContainer = form.closest('.js-inline-comments-container')
      const newCommentContainer = fragment.querySelector('.js-inline-comments-container')

      if (commentContainer && newCommentContainer) {
        commentContainer.replaceWith(newCommentContainer)
        replaced = true
      }
    }

    if (!replaced) {
      container.replaceWith(fragment)
      replaced = true
    }
  }

  blurForm(form)
})

function resetInlineCommentError(form: HTMLFormElement) {
  const error = form.querySelector<HTMLDivElement>('.js-comment-form-error')
  if (error) {
    error.hidden = true
    error.textContent = null
  }
}

function handleInlineCommentError(form: HTMLFormElement, data: {errors: string[] | string}) {
  const error = form.querySelector<HTMLElement>('.js-comment-form-error')!

  let message
  if (data.errors) {
    if (Array.isArray(data.errors)) {
      message = data.errors.join(', ')
    } else {
      message = data.errors
    }
  } else {
    // eslint-disable-next-line i18n-text/no-en
    message = 'There was an error posting your comment.'
  }

  error.textContent = message

  // This remains for backwards-compatibility with error elements that do not
  // use the 'd-none' utility class.
  error.style.display = 'block'

  error.hidden = false

  // On a formError element that uses the 'd-none' utility class, setting the
  // style attribute directly does not work because 'd-none' is declared as
  // !important. Instead, remove 'd-none'.
  /* eslint-disable-next-line github/no-d-none */
  error.classList.remove('d-none')
}

// Resume reply comment form on existing thread.
function showReplyForm(id: string) {
  const [anchor, threadReplyToId] = id.match(/^new_inline_comment_(?:discussion|diff)_(?:[\w-]+)_(\d+)_(\d+)$/) || []
  if (!anchor) return

  const input = document.querySelector(`.js-inline-comment-form input[name='in_reply_to'][value='${threadReplyToId}']`)
  if (!input) return

  const form = input.closest('.js-line-comments')
  if (form) focusForm(form)
}

// Resume new thread comment form.
function showCommentForm(id: string) {
  const [anchor, position] = id.match(/^new_inline_comment_diff_(?:[\w-]+)_(\d+)$/) || []
  if (!anchor) return

  const button = document.querySelector<HTMLButtonElement>(
    `.js-add-line-comment[data-anchor='${anchor}'][data-position='${position}']`,
  )
  if (button) button.click()
}

// Support session resume on dynamically inserted line comment forms.
document.addEventListener('session:resume', function (event: Event) {
  const detail = (event as CustomEvent).detail
  showReplyForm(detail.targetId)
  showCommentForm(detail.targetId)
})

// Ensure "Show notes" is checked for file.
//
// file - .file Element
function ensureInlineNotesEnabled(file: Element) {
  const checkbox = file.querySelector('.js-toggle-file-notes')
  if (checkbox instanceof HTMLInputElement) {
    changeValue(checkbox, true)
  }
}

// Anytime a inline comment is removed from the DOM, rescan for comment
// holders that don't have any children.
function cleanupInlineCommentContainers() {
  for (const container of document.querySelectorAll('.file .js-inline-comments-container')) {
    const comments = container.querySelectorAll('.js-comments-holder > *')
    const hasComments = comments.length > 0
    const isResolved = container.querySelector("[data-resolved='true']") != null
    const form = container.querySelector('.js-inline-comment-form-container')
    const formOpen = !!form && form.classList.contains('open')
    if (!(hasComments || isResolved) && !formOpen) {
      container.remove()
    }
  }
}

observe('.js-comment', {
  remove: cleanupInlineCommentContainers,
})

on('inlinecomment:focus', '.js-inline-comment-form-container', function (e) {
  const suggestionContainer = (e.target as Element).querySelector('.js-suggested-changes-container')
  if (suggestionContainer) loadSuggestionButton(suggestionContainer)
})

observe('.js-suggested-changes-container.is-comment-editing', el => {
  loadSuggestionButton(el)
})

function paramsForSuggestionButton(url: URL, form: HTMLFormElement): URLSearchParams | null {
  const params = new URLSearchParams(url.search.slice(1))

  const commentIdInput = form.elements.namedItem('in_reply_to') || form.elements.namedItem('comment_id')

  const textarea = form.querySelector('textarea') as HTMLTextAreaElement
  params.append('textarea_id', textarea.id)

  if (commentIdInput instanceof HTMLInputElement && commentIdInput.value) {
    params.append('comment_id', commentIdInput.value)
    return params
  }
  const pathInput = form.elements.namedItem('path')
  const startSideInput = form.elements.namedItem('start_side')
  const startLineInput = form.elements.namedItem('start_line')
  const endSideInput = form.elements.namedItem('side')
  const endLineInput = form.elements.namedItem('line')
  const startCommitOidInput = form.elements.namedItem('start_commit_oid')
  const endCommitOidInput = form.elements.namedItem('end_commit_oid')
  const baseCommitOidInput = form.elements.namedItem('base_commit_oid')
  const subjectType = form.elements.namedItem('subject_type')

  if (
    !(pathInput instanceof HTMLInputElement) ||
    !(startSideInput instanceof HTMLInputElement) ||
    !(startLineInput instanceof HTMLInputElement) ||
    !(endSideInput instanceof HTMLInputElement) ||
    !(endLineInput instanceof HTMLInputElement) ||
    !(startCommitOidInput instanceof HTMLInputElement) ||
    !(endCommitOidInput instanceof HTMLInputElement) ||
    !(baseCommitOidInput instanceof HTMLInputElement) ||
    !(subjectType instanceof HTMLInputElement)
  ) {
    return null
  }

  params.append('path', pathInput.value)
  params.append('start_side', startSideInput.value)
  params.append('start_line', startLineInput.value)
  params.append('end_side', endSideInput.value)
  params.append('end_line', endLineInput.value)
  params.append('start_commit_oid', startCommitOidInput.value)
  params.append('end_commit_oid', endCommitOidInput.value)
  params.append('base_commit_oid', baseCommitOidInput.value)
  params.append('subject_type', subjectType.value)

  return params
}

async function loadSuggestionButton(container: Element) {
  const el = container.querySelector('.js-suggestion-button-placeholder')
  if (!el) {
    return
  }

  const srcBase = el.getAttribute('data-src-base')
  if (!srcBase) {
    return
  }

  const url = new URL(srcBase, window.location.origin)
  const form = el.closest('.js-inline-comment-form') || el.closest('.js-comment-update')
  if (!(form instanceof HTMLFormElement)) {
    return
  }

  const params = paramsForSuggestionButton(url, form)
  if (!params) {
    return
  }

  url.search = params.toString()

  let button

  // if the user cannot access the link (eg, 401), we bail
  try {
    button = await fetchSafeDocumentFragment(document, url.toString())
  } catch {
    return
  }

  el.textContent = ''
  el.appendChild(button)
}

document.addEventListener('inlinecomment:collapse', () => {
  cleanupInlineCommentContainers()
})

// Return existing line comments container or replace empty cells with the form
// template.
//
// tr        - A HTMLTableRowElement .file-diff-line
// className - 'js-addition' or 'js-deletion'
//
// Returns .js-line-comments HTMLTableCellElement.
function expandLineCommentsEmptyCells(
  threadRow: Element,
  className: 'js-addition' | 'js-deletion',
  button: Element,
): Element {
  const found = threadRow.querySelector(`.js-line-comments.${className}`)
  if (found) {
    return found
  }
  const lineComments = renderCommentTemplate('js-inline-comments-split-form-container-template', button)
    .firstElementChild!
  lineComments.classList.add(className)

  const empty = threadRow.querySelectorAll(`.${className}`)
  const last = empty[empty.length - 1]!
  last.after(lineComments)
  for (const el of empty) el.remove()

  return lineComments
}

// Find or insert inline comments container for diff line.
//
// tr - A HTMLTableRowElement .file-diff-line
//
// Returns a .js-inline-comments-container HTMLTableRowElement.
function inlineCommentsSplitContainerForLine(tr: Element): Element {
  let nextRow: Element | null = next(tr, '.js-inline-comments-container')
  if (nextRow) {
    return nextRow
  } else {
    const id = 'js-inline-comments-split-container-template'
    const template = document.getElementById(id)
    if (!template) {
      throw new Error(`Could not find element with id ${id}`)
    }
    if (!(template instanceof HTMLTemplateElement)) {
      throw new Error(`Found element with id ${id} - but was not a Template`)
    }
    nextRow = new TemplateInstance(template, {}).firstElementChild!
    tr.after(nextRow)
    return nextRow
  }
}

function applyMultiLinePreview(element: Element, lineNumber: string, side: string | undefined, isContext: boolean) {
  element.classList.remove('color-bg-success', 'color-bg-danger')

  if (isContext) {
    element.textContent = lineNumber
    return
  }

  if (side === 'right') {
    element.classList.add('color-fg-success')
    element.textContent = `+${lineNumber}`
    return
  }

  if (side === 'left') {
    element.classList.add('color-fg-danger')
    element.textContent = `-${lineNumber}`
    return
  }

  element.textContent = String(lineNumber)
}

const sides: {[key: string]: string} = {R: 'right', L: 'left'}

function fillMultilineFormFields(form: HTMLFormElement, button: Element) {
  const currentRange = getCurrentRange()

  if (!currentRange) {
    return
  }

  // If the start and end are the same position it's not multi-line.
  // We don't want to clear the selection though, so we handle this separate.
  if (currentRange.end.is(currentRange.start)) {
    return
  }

  if (!isMultiLineCommentAllowed(button, currentRange)) {
    clearSelection()
    return
  }

  // Grab the stuff we care about
  const {
    start: {lineNumber: startLineNumber},
    end: {lineNumber: endLineNumber},
  } = currentRange
  let {
    start: {side: startSide},
    end: {side: endSide},
  } = currentRange

  const buttonLine = Number(button.getAttribute('data-line'))
  const buttonSide = button.getAttribute('data-side')

  if (
    buttonLine !== endLineNumber ||
    // Context lines always have data-side set to 'right', so skip
    // comparing the sides if we're ending on a context line.
    (!currentRange.end.isContext() && buttonSide !== sides[endSide])
  ) {
    // Deselect
    clearSelection()
    return
  }

  const sideValue = sides[startSide]

  // These attributes get automatically converted to params in fillTemplateFormFields from line-comments.js

  const startLineEl = form.elements.namedItem('start_line')
  const startSideEl = form.elements.namedItem('start_side')
  const endLineEl = form.elements.namedItem('line')
  const endSideEl = form.elements.namedItem('side')
  const previewStartSideEl = form.elements.namedItem('preview_start_side')
  const previewSideEl = form.elements.namedItem('preview_side')
  if (
    startLineEl instanceof HTMLInputElement &&
    startSideEl instanceof HTMLInputElement &&
    endLineEl instanceof HTMLInputElement &&
    endSideEl instanceof HTMLInputElement &&
    previewStartSideEl instanceof HTMLInputElement &&
    previewSideEl instanceof HTMLInputElement
  ) {
    startLineEl.value = String(startLineNumber)
    startSideEl.value = sideValue!
    startSideEl.value = previewStartSideEl.value = startSideEl.value
    previewSideEl.value = endSideEl.value

    const startLine = startLineEl.value
    startSide = startSideEl.value
    const endLine = endLineEl.value
    endSide = endSideEl.value

    const container = form.closest<HTMLElement>('.js-inline-comment-form-container')!

    if (startLine && endLine) {
      const multiLinePreview = container.querySelector<HTMLElement>('.js-multi-line-preview')!
      const multiLinePreviewStart = multiLinePreview.querySelector<HTMLElement>('.js-multi-line-preview-start')!
      const multiLinePreviewEnd = multiLinePreview.querySelector<HTMLElement>('.js-multi-line-preview-end')!

      const startIsContext = currentRange.start.isContext()
      const endIsContext = currentRange.end.isContext()

      applyMultiLinePreview(multiLinePreviewStart, startLine, startSide, startIsContext)
      applyMultiLinePreview(multiLinePreviewEnd, endLine, endSide, endIsContext)

      multiLinePreview.hidden = false
      container.classList.add('is-multiline')
    } else {
      container.querySelector<HTMLElement>('.js-multi-line-preview')!.hidden = true
      container.classList.remove('is-multiline')
    }

    validateInlineCommmentsContainer(container)
  }
}

function fillTemplateFormFields(form: HTMLFormElement, from: Element) {
  const keys = ['type', 'path', 'position', 'line', 'side', 'original-line']

  for (const key of keys) {
    const field = form.elements.namedItem(key)
    if (field instanceof HTMLInputElement) {
      const value = from.getAttribute(`data-${key}`) || ''
      field.value = value
    }
  }

  const subjectType = form.elements.namedItem('subject_type')
  if (subjectType instanceof HTMLInputElement) {
    const value = from.getAttribute(`data-subject-type`) || ''
    subjectType.value = value
  }

  // Update 'expanded_diff' form input field based on `from` element's 'data-expanded-diff' attribute
  const expandedDiffField = form.getElementsByClassName('js-expanded-diff-placeholder-value')?.[0]
  if (expandedDiffField instanceof HTMLInputElement) {
    const value = from.getAttribute(`data-expanded-diff`) || ''
    expandedDiffField.value = value
  }

  fillMultilineFormFields(form, from)
}

// Render template instance with given name and button data.
function renderCommentTemplate(id: string, button: Element) {
  const template = document.getElementById(id)
  if (!template) {
    throw new Error(`Could not find element with id ${id}`)
  }
  if (!(template instanceof HTMLTemplateElement)) {
    throw new Error(`Found element with id ${id} - but was not a Template`)
  }

  return new TemplateInstance(template, {
    anchor: button.getAttribute('data-anchor') || '',
    position: button.getAttribute('data-position') || '',
    subject_type: button.getAttribute('data-subject-type') || '',
    sequence: sequence++,
  })
}
